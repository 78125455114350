body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dusty Rose: #DAA9A9
Elegant Gray: #777777
Creamy White: #F5F5F5
Slate Blue: #6B8EAE
Sage Green: #9BC995 */

/* Soft White: #F8F8F8
Coral Pink: #FF6F61
Magenta: #FF3385
Turquoise Blue: #4CC3D9
Forest Green: #228B22
Charcoal Gray: #333333 */