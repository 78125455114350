.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
  position: fixed;
  top: 0;
  background-color: #333333;
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  padding-left: 32px;
  color: #f8f8f8;
  text-decoration: none;
}

.navbar-logo:hover {
  color: #f8f8f8;
}

.navbar-links {
  display: flex;
  text-align: center;
}

nav li {
  list-style: none;
}

.navbar-links a {
  display: block;
  padding: 8px;
  text-decoration: none;
  color: #f5f5f5;
}

/* why doesn't the active work? */
.navbar-links a:hover,
.navbar-links a.active {
  color: #ff6f61;
}

.navbar .icon {
  display: none;
}

.navbar-button {
  padding-right: 32px;
}

button {
  font-size: x-large;
  font-weight: bold;
  padding: 1.5rem 2rem;
  border-radius: 12px;
  border: none;
  margin: 8px;
  background-color: #ff3385;
  color: #f5f5f5;
}

button:hover {
  background-color: #228b22;
  cursor: pointer;
}

main {
  background-color: #f5f5f5;
  min-height: 100vh;
  color: #333333;
}

.hero {
  max-width: 940px;
}

section {
  margin: auto;
  max-width: 1280px;
}

.hero,
.value-props,
.how-to,
.testimonials {
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.hero-message {
  margin-bottom: 0;
}

.section-title {
  margin-top: 6em;
}

.link {
  list-style: none;
  padding: 0;
  font-size: 1rem;
}

a {
  color: #ff6f61;
}

a:hover {
  color: #228b22;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
}
.icon-img {
  filter: opacity(80%);
  max-height: 175px;
  margin-top: 250px;
  z-index: -100;
}

.highlight {
  background-color: #ff6f6180;
  font-weight: bold;
  color: black;
  padding-top: 4px;
  padding-bottom: 4px;
}

caption {
  font-size: xx-large;
  padding-bottom: 18px;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #333333;
  color: #f5f5f5;
}

tr:hover {
  background-color: #ff6f61;
  color: #f5f5f5;
}

footer {
  background-color: #333333;
  padding: 32px;
  color: #f8f8f8;
  text-align: center;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media screen and (max-width: 600px) {
  section {
    width: 75%;
  }

  .icon-img {
    margin-top: 125px;
  }
  .navbar.close {
    min-height: 50px;
    flex-direction: row; 
  }

  .navbar.close .navbar-links,
  .navbar.close .navbar-button {
    display: none;
  }

  .navbar .icon {
    float: right;
    display: block;
    padding: 1.5rem 2rem;
  }

  .navbar-links {
    flex-direction: column;
  }

  nav ul {
    padding-left: 0;
  }
  
  .navbar.open {
    flex-direction: column;
  }

  .row {
    grid-template-columns: repeat(1, 1fr);
  }

  .navbar.open .navbar-links,
  .navbar.open .navbar-button {
    display: flex;
    flex-direction: column;
  }

  .navbar.open .navbar-button {
    padding: 0;
  }
}

/* 1240 break point */